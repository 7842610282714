const mix1Description = (
  <>
    Like the SP-01, our selector series of “tracks for a good hi-fi system” is
    dedicated to the home listening party. We asked our friend and artist Jessy
    Lanza to craft the inaugural mix and it’s a stunner.
    <br />
    <br /> Jessy Lanza has built a singular sound from a blend of influences
    spanning decades, encompassing synth pop, post-disco R&B, and the
    crosscurrents of underground dance genres. A singer, songwriter,
    instrumentalist, producer, and DJ, Jessy is an integral part of the U.K.
    label, Hyperdub.
    <br />
    <br /> Begonia leaf by Eric Epstein.
  </>
);

const mix2Description = (
  <>
    Jen Monroe is an artist, chef, and food designer known for her culinary
    project, Bad Taste, in which she crafts thoughtful fantasy worlds through
    food. Her exquisite and expansive taste stretches to music, too. She is the
    creator and curator of the venerable long-form music blog, Listen To This,
    and the host of NTS radio’s Getting Warmer (we listen to her Choral Special
    every season).
    <br />
    <br /> Jen ran with the brief and delivered a beautiful, spirited hour of
    “tracks for a good hi-fi” for our second WAF mix, which we’ll be listening
    to over and over.
    <br />
    <br /> Macro pollen by Eric Epstein
  </>
);

const mix3Description = (
  <>
    CZ Wang brings us a leftfield dreamscape for our third mix, perfectly timed
    for warmer weather.
    <br />
    <br />
    The New York-based artist, engineer, producer, and DJ is a co-founder of the
    flawless Canadian label Mood Hut, a long time favorite of ours for hazy
    house and drippy new age gems. Wang’s lengthy list of production credits
    include FKA Twigs, Oneohtrix Point Never, Caroline Polachek, and David
    Byrne. He lent us his well-tuned ear for this gorgeous sleepwalk.
    <br />
    <br /> Macro flower by Eric Epstein
  </>
);

const mix4Description = (
  <>
    Multifaceted Glasser opens the gate to a secret garden of esoteric flora for
    our 4th selector mix, featuring an exclusive early release of her beautiful
    new track, ‘Om’ from her collection crux deluxe, coming out this fall. Lush
    and full of oxygen, this mix drifts like a verdant cross-breeze.
    <br />
    <br />
    Glasser is a new york-based artist and producer known for her experimental
    soundscapes, ethereal voice, and mesmerizing performances. She continues to
    push the boundaries of contemporary pop.
    <br />
    <br /> Macro spores by Eric Epstein
  </>
);

const mix5Description = (
  <>
    Full of detail and movement, sound architect Priori shares a personal
    snapshot of music he would reach for while “sitting in front of a good hifi”
    for our 5th selector mix.
    <br />
    <br />
    A key voice in Canada’s underground electronic music landscape, Priori
    (Francis Latreille) is a celebrated producer and sought-after mixing
    engineer. As half of NAFF recordings and Jump Source recording studio, he
    comfortably walks the boundaries of underground dance music, delicately
    blending organic and synthetic worlds.
    <br />
    <br /> Macro slime trail by Eric Epstein
  </>
);

const mix6Description = (
  <>
    We’re starting out the year with a new selector mix by Olive Kimoto, our
    go-to for oozy shoegaze and lush, lower bpm gems. An ethereal leitmotif for
    the blurring of winter days. Play this mix on repeat.
    <br />
    <br />
    Olive Kimoto is an LA-based musician, multimedia artist, and DJ. We never
    miss her monthly radio show, Liquid Mirror, on NTS.
    <br />
    <br /> Macro leaf by Eric Epstein
  </>
);

const songs = [
  {
    artistName: "Jessy Lanza",
    SongName: "WAF MIX - 01",
    description: mix1Description,
    img: "https://i.ibb.co/NKQMThY/WAF-mix-01-Jessy-Lanza.jpg",
    mp3File: "https://api.wafaudio.com/mixes/mix1.mp3",
  },
  {
    artistName: "Jen Monroe",
    SongName: "WAF MIX - 02",
    description: mix2Description,
    img: "./Jen-mix.jpg",
    mp3File: "https://api.wafaudio.com/mixes/mix2.mp3",
  },
  {
    artistName: "CZ Wang",
    SongName: "WAF MIX - 03",
    description: mix3Description,
    img: "https://i.ibb.co/C0ZtCcB/WAF-mix-3-CZ-Wang.jpg",
    mp3File: "https://api.wafaudio.com/mixes/mix3.mp3",
  },
  {
    artistName: "Glasser",
    SongName: "WAF MIX - 04",
    description: mix4Description,
    img: "./mix4.jpeg",
    mp3File: "https://api.wafaudio.com/mixes/mix4.mp3",
  },
  {
    artistName: "Priori",
    SongName: "WAF MIX - 05",
    description: mix5Description,
    img: "./mix5.jpeg",
    mp3File: "https://api.wafaudio.com/mixes/mix5.mp3",
  },
  {
    artistName: "Olive Kimoto",
    SongName: "WAF MIX - 06",
    description: mix6Description,
    img: "./mix6.jpeg",
    mp3File: "https://api.wafaudio.com/mixes/mix6.mp3",
  },
].reverse();

export default songs;
